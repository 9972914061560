import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerSmall from "../components/media/BannerSmall"
import Header from "../components/Header/Header"
import Untappdfeed from "../components/untappdfeed"
import ContactForm from "../components/contactform"

const Beers = () => (
  <Layout>
    <SEO title="Craft Beers in Guildford" />
    <Header />
    <section className="Hero">
      <BannerSmall />
    </section>
    <main>
      <section>
        <div className="OneColumn">
          <h2>Currently on tap</h2>
          <Untappdfeed type="taps" />
        </div>
      </section>
      <section>
        <div className="OneColumn">
          <h2>In our fridges</h2>
          <Untappdfeed type="fridge" />
        </div>
      </section>
      <section>
        <div className="OneColumn">
          <h2>Want something on the list or trying to find an elusive brew?</h2>
          <p>Fill out the form below and we will get back to you ASAP</p>
          <ContactForm />
        </div>
      </section>
    </main>
  </Layout>
)

export default Beers
