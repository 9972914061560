import React, { useEffect } from "react"
import { withPrefix } from "gatsby"

import "./untappdfeed.css"

const Untappdfeed = (props) => {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = withPrefix("untappd.js");
    script.async = true;
    document.body.appendChild(script);
  });


  return (
    <div id={"menu-container-" + props.type}>
    </div>
  )
}

export default Untappdfeed